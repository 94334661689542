import firebase from "firebase";
import "firebase/firestore";
import "firebase/analytics";

//PRODUCTION
var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

//STAGING
// var config = {
//   apiKey: process.env.VUE_APP_STAGING_FIREBASE_API_KEY,
//   authDomain: process.env.VUE_APP_STAGING_AUTH_DOMAIN,
//   databaseURL: process.env.VUE_APP_STAGING_DATABASE_URL,
//   projectId: process.env.VUE_APP_STAGING_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_STAGING_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_STAGING_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_STAGING_APP_ID,
//   measurementId: process.env.VUE_APP_STAGING_MEASUREMENT_ID,
// };

firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
// const googleProvider = new firebase.auth.GoogleAuthProvider();
// const facebookProvider = new firebase.auth.FacebookAuthProvider();
const currentUser = auth.currentUser;
const storage = firebase.storage();
const functions = firebase.functions();
const fieldValue = firebase.firestore.FieldValue;
const timestamp = firebase.firestore.Timestamp;
const analytics = firebase.analytics();
const settings = {};
db.settings(settings);

// firebase collections
// const fb = {
//   db,
//   auth,
//   functions,
//   currentUser,
//   fieldValue,
//   timestamp,
//   storage,
//   analytics,
//   // googleProvider,
//   // facebookProvider,
// };
export {
  db,
  auth,
  functions,
  currentUser,
  fieldValue,
  timestamp,
  storage,
  analytics,
  // googleProvider,
  // facebookProvider,
};
