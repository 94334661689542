import Vue from "vue";
import Router from "vue-router";
import DigitalCertificate from "./views/DigitalCertificate.vue";
import DigitalCertificateList from "./views/DigitalCertificateList.vue";
import Redirect from "./views/Redirect.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/digitalcertlist/:list_id",
      name: "DigitalCertificateList",
      component: DigitalCertificateList,
    },
    {
      path: "/:type/:certificate_num",
      name: "DigitalCertificate",
      component: DigitalCertificate,
    },
    {
      path: "/redirect",
      name: "Redirect",
      component: Redirect,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.fullPath == "/" || !Object.keys(to.params).length) {
    //  return window.location = "https://diamondservicesusa.com/";
  }
  next();
});

export default router;
