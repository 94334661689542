import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import utilities from "./utilities/utility";
import { auth } from "./firebaseConfig.js";
import { firestorePlugin } from "vuefire";
import "./registerServiceWorker";

Vue.use(require("vue-moment"));
Vue.use(firestorePlugin);
Vue.mixin(utilities.mixin());

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
