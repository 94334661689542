<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showUIUpdate(e) {
      this.registration = e.detail;
      this.updateAvailable = true;
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style src="../public/css/main.css"></style>
